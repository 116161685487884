@media all and (max-width: 900px){
  .bioHold{margin-bottom: var(--lrg);}
  .cvHold{width:100%;}
}
@media all and (max-width: 600px){
  .aboutPortrait{width:100%;margin-bottom:var(--sm)}
  /* .rPortrait{padding-bottom:100%;} */
  .aboutOptDes, .aboutOptTitle{width:100%;}
  .aboutOptDet{width:50%}
  .aboutLine{padding-left:var(--xs)}
}
