.leftCorner{
    position: absolute;
    top:var(--sm);
    left: var(--huge);
    z-index: 700;
}

.leftBottom{
    position: absolute;
    bottom:0;
    left: 0;
    z-index: 700;
}

.introItem{
    opacity:0;
    top:50%;
    left:50%;
    position: fixed;
    z-index:800;
    transform-origin: left top;
    /* filter: blur(0); */
}

.finishHold{
    position: fixed;
    z-index:10;
    top:0;
    left:0;
}
/* animation */
.enterLeaveSlow{
    animation: enterLeave .4s linear forwards;
}

.enterLeaveSlowImg{
    animation: enterLeaveImg .35s linear forwards;
}

.enterLeaveFastImg{
    animation: enterLeaveImg .2s linear forwards;
}

.slowFadeIn{
    opacity:0;
    animation: slowFadeIn 5s ease-in forwards;

}
.landingTitle{
    z-index:500;
}

.infoBio{
    position: absolute;
    bottom: var(--huge);
    left: 50%;
    width:580px;
    transform: translateX(-50%);
    z-index:500;
}
.gridHold{
    position:fixed;
    top:0;
    left:0;
    z-index:1;
}

.consoleHold{
    position: absolute;
    top:0;
    left:0;
    z-index:700;
}




.gridCol{
    position: absolute;
    width:1px;
    border-right: 1px solid var(--white);
    height:0;
    opacity:.2;
}

.gridRow{
    position: absolute;
    height:1px;
    border-bottom: 1px solid var(--white);
    width:0;
    opacity:.2;
}

.growWidth{
    animation: widthIn 1.5s ease-in forwards;
}

.growHeight{
    animation: heightIn 1.5s ease-in forwards;
}

.singleConsole{
    position:absolute;
    width:35%;}

.consoleWork{
    width:35%;
}

.consoleConvo{
    width:22%;
}

.consoleThoughts{
    width:25%
}


.consoleAbout{
    width:21%;
}

.consoleTitle{
    z-index:999;
}

.consoleTitle sup{
    border: none;
}

.singleConsole .consoleTitle.hoverOn sup{
    animation: blinker .5s linear infinite;
}


@keyframes enterLeave {
    0% {
        opacity:1;
        transform: scale(8) translate(-50%,-65%);
    }
    100%{
        opacity:1;
        transform: scale(3) translate(-50%,-65%);
    }
  }

  @keyframes slowFadeIn {
    0% {opacity:0;
     }
     100%{
         opacity:1;
     }
   }


  @keyframes heightIn {
    0% {height:0;
     }
     100%{
         height:100%;
     }
   }

   @keyframes widthIn {
    0% {width:0;
     }
     100%{
         width:100%;
     }
   }

  @keyframes enterLeaveImg {
    0% {opacity:1;
         transform: scale(1) translate(-50%,-50%);
     }
     100%{
         opacity:1;
         transform: scale(.7) translate(-50%,-50%);
     }
   }

@media all and (max-width:1150px){


    .consoleWork{width:60%;}
    .consoleConvo{width:24%; }
    .consoleThoughts{width:29%}
    .consoleAbout{width:25%;}
}

   @media all and (max-width: 800px){
    .leftCorner{left:var(--lrg)}
    .consoleWork{width:60%;}
    .consoleConvo{width:38%; }
    .consoleThoughts{width:35%}
    .consoleAbout{width:30%;}
}


   @media all and (max-width: 600px){
    .leftBottom .col5{width:100%;margin-top:var(--sm)}
    .consoleWork{width:85%;}
    .consoleConvo{width:55%; }
    .consoleThoughts{width:57%}
    .consoleAbout{width:50%;}
    .infoBio{width:80%;bottom:initial;top:50%;transform: translate(-50%,100%);}
}
