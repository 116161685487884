.screenHold{
    position:fixed;
    z-index:999;
    top:0;
    left:0;
}

.clockHold{
    z-index:500;
}

.screenItem{
    position:absolute;
    width:500px;
    z-index:600;
    overflow:hidden;
}

.screenVideo{
    opacity:1;
    position:absolute;
    width:calc(100vw - var(--huge));
    height:calc(100vh - var(--huge));
    z-index:600;
    transition:all 1s ease-out ;
}

.screenVideo.hide{
    opacity:0;
    transition:all 1s ease-out ;
}

@media all and (max-width: 800px){
    .tClock{font-size: 10rem;}
    .screenItem{width: 400px;}
	.clockHold {height: calc(100vh - var(--med) * 2);display:flex;padding-left:0;padding-right:0;}
    .topClock, .botClock{width: 50%; height:100%;flex-direction: column;}
    .clockHold .colThird{width: 100%;}


}

@media all and (max-width: 600px){
    .screenItem{width:65%;}
    .screenVideo{
        width:calc(100vw - var(--sm)*2);
        height:calc(100vh - var(--sm)*2);
    }

}
@media all and (max-width: 600px) and (max-height:1200px){
    .clockHold{height:calc(100vh - var(--lrg) * 2)}
    .tClock{font-size: 9rem;}
}

@media all and (max-width: 600px) and (max-height:600px){
    .clockHold{height:calc(100vh - var(--med) * 2)}
    .tClock{font-size: 8rem;}
}