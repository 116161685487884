#debug{
    position:fixed;
    display:block;
    top:50%;
    right:var(--sm);
    transform:translateY(-50%);
    z-index:950;
}

#bg{
    position: fixed;
    top:0;
    left:0;
    z-index: 0;
}

#ui{
    z-index:900;
}
#appContain{z-index:500;}
#map{
    position:fixed;
    bottom:var(--med);
    right: 0;
    padding: var(--sm);
    z-index:950;

}

#logo{
    position:fixed;
    width:50px;
    bottom:calc(var(--sm));
    right:var(--sm);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--med);
}
#cross{
    overflow: hidden;
    mix-blend-mode: difference;
}

#menu, #subMenu{
    position:absolute;
    top: 50%;
    left:0;
    transform:translateY(-50%);
    z-index:950;
}

#geo{
    position:absolute;
    top:var(--sm);
    right:var(--sm);
}

.popUpHold{
    position:absolute;
    z-index: 950;
    top:0;
    left:0;
}
#content{
    position:relative;
    z-index:100;
}

#path{
    position:fixed;
    top:var(--sm);
    left:var(--huge);
    z-index:901;
}

#footer{
    position:fixed;
    width:auto;
    max-width:60%;
    bottom: var(--sm);
    left: var(--huge);
    z-index: 900;
}
.singleMenu{
    padding: var(--xs) var(--xs) var(--xs) var(--huge);
}

.menuBut{
    position:absolute;
    bottom: 0;
    left:0;
    padding:30px;
    z-index:999;
}

.subMenuBut{
    position:absolute;
    top:0;
    left:0;
    padding-top:var(--sm);
    padding-left:var(--huge);
    padding-bottom: var(--med);
    z-index:999;
}

/* viewport */
.viewport{
    width:120px;
    height:120px;
    backdrop-filter: saturate(180%) blur(15px);
    background-color: rgba(15,15,15,0.5);



}


.viewScale{
    position: absolute;
}



.dot{
    position:absolute;
    box-sizing: border-box;
    content:"";
    border-style:solid;
    border-color:var(--white);
    border-width:1px;
    border-radius: 100%;
    width:24px;
    height:24px;
    transition:all ease-in .1s;
    transform-origin: center;
}

.dotBot{
    left:var(--sm);
    bottom:var(--sm);
}

.dotTop{
    left:var(--sm);
    top:var(--sm);
}

.dotMid{
    left:var(--sm);
    top:50%;
    transform:translateY(-50%)
}


.subDot{
    left:var(--sm);
    bottom:50%;
    opacity:0;
    transform: translateY(50%);
    animation: normOn .5s 1 forwards;
}

.menuBut:hover .dot, .menuBut.active .dot, .dotHold:hover .dot,  .subMenuBut.active .dot, .singlePop .dot{
    border-width:12px;
    transform: scale(.8);
   transform-origin: center center;
}
.dotHold:hover .dotMid, .dotMid.active{
    border-width:12px;
    transform: scale(.8) translateY(-50%);
   transform-origin: center top;
}

.singleMenu:hover .dot, .singleMenu.active .dot{
    border-width:12px;
    transform: translateY(50%) scale(.8);
}

.crossHair{
    position: absolute;
    transition: transform .5s ease-out;
    z-index:950;
    transform-origin: center;
}


.crossX{
    border-bottom:1px solid var(--white);
    transform: scaleX(0) translateX(-50%);
}

.crossY{
    border-left:1px solid var(--white);
    transform: scaleY(0) translateY(-50%);
}

.crossX.active{
    transform: scaleX(1) translateX(-50%);
}
.crossY.active{
    transform: scaleY(1) translateY(-50%);
}

/* toolbar */
.toolBar{
    position: fixed;
    z-index: 999;
    opacity: 1;
}

#toolText{
    white-space: nowrap;
}
.onNorm .toolLetter{
    opacity:0;
    animation: typeOn .15s 1 forwards;
}

.singleMenu.hoverOn span, .subMenuBut.hoverOn span, .toggleTypeOn.hoverOn span{
    opacity:1;
    animation: typeOff .15s 1 forwards;
}

.contentStage.menuActive, .blurStage.active{
    filter: blur(20px) brightness(70%) saturate(180%);
}

.timeHold{
    width:80px;
}


.subMenuLabel{
    white-space: nowrap;
}

.ytHold{
    width:100%;
    height:100%;
    position:absolute;
    left:0;
    top:0;
    z-index:999;
}

.ytHold iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
}

.eFiller{
    z-index:0;
}

.dim{
    opacity:.5;
}

/* map */
.mapField{
    will-change: transform;
    z-index:500;
}
.map-1{
    z-index:500;
}

.map-2{
    position: absolute;
    z-index:550;
}

.hover .singleWorkLink{
    opacity:0;
    transition: none;
}

.singleContent{
    opacity: 1;
    position: absolute;
    transition: filter .5s ease-out;
}

.hoverActive .singleContent{
    filter: brightness(15%);
}

.hoverActive .hoverOn{
    filter: brightness(100%)
}

.secDescription{
    z-index:560;
}

.secDescription.pause{
    z-index:501;
}

.desHold{
    display:inline-block;
    padding-left:var(--huge);
    padding-right:var(--huge);
}

.coverBut{z-index:10;}


.contentStroke{
    border-style: solid;
    border-width:1px;
    border-color: var(--white);
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    z-index:999;
    transition: all .1s ease-in;
}

.inspireSingle:hover .contentStroke{
    width:calc(100% - var(--med));
    height:calc(100% - var(--med));
    border-width: 8px;
}

.blinkOn{
    opacity:0;
    animation: blinkOn .1s 4 linear forwards;
}

.blinkFast{
    animation: blinkFast 5ms linear infinite;
}

.fadeOn{
    opacity:0;
    animation:fadeOn .75s ease-in-out forwards;
}

.textFadeOn{
    opacity:0;
    animation: typeOn .25s linear forwards
}

.proTool{
    position: absolute;
    top:50%;
    right:0;
    transform: translate(calc(100% + 20px),-50%);
}

.contentTool{
    position: absolute;
    bottom:0;
    left:0;
    padding:var(--sm) ;
    transform: translateY(100%);
}






/* animation */

@keyframes blinkOn {
    70%{
        opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOn {
    100% {
      opacity: 1;
    }
  }
@keyframes typeOn {
    0% {
      opacity:0;
    }

    70% {
      opacity:0;
    }
    100%{
    opacity:1;
    }
  }
  @keyframes typeOff {
    0% {
      opacity:1;
    }

    70% {
      opacity:1;
    }
    100%{
    opacity:0;
    }
  }

  @keyframes normOn {
    0% {
      opacity:0;
    }

    100%{
    opacity:1;
    }
  }


  @keyframes blinkFast {
    0% {
      opacity:.5;
    }

    97%{
    opacity:1;
    }
  }

  .singlePop.imageLink{
      width: 45vw;
      position: absolute;
      z-index:1;
  }
  .singlePop.videoLink{
    width: 55vw;
    position: absolute;
    z-index:1;
}

  .imageHold{
    padding: var(--sm);
  }

  .singlePop .sizer, .singleConsole .sizer{
      z-index:999;
      position: absolute;
      bottom:0;
      right:0;
      width:60px;
      height:60px;
  }


  .popStroke{
      position: absolute;
      width:100%;
      height:100%;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
      transition: all .2s ease-out;
      z-index:1;
  }

  .singlePop{
      transform:translate(-50%,-50%);
  }

  .singlePop:hover{
      z-index:500;
  }
  .singlePop:hover .popStroke, .singleConsole:hover .popStroke{
      width: calc(100% - var(--xs));
      height: calc(100% - var(--xs));
      border-width: 8px;

  }

  .singlePop .imageHold, .singleConsole .imageHold{
      position:relative;
      z-index:10;
  }

/* iframe{
      position:absolute;
      width:100%;
      height:100%;
      top:0;
      left:0;
  } */

.singlePop .closeHold{
    position: absolute;
    top:50%;
    left:-35px;
    z-index: 999;
    border-radius: 100%;
    transform: translateY(-50%);
}

.dragging{
    z-index: 999;
}
.dragging iframe{
    pointer-events: none;
}

.singlePop .dragBuff, .singleConsole .dragBuff{
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    left:0;
    z-index:0;
}

.closeHold .dot{
    position:relative;
}

.titleHold{
    position:fixed;
    top:var(--med);
    left:var(--huge);
    z-index:600;
}

.proDescription{padding-right:var(--huge)}

@media all and (max-width: 1200px){
    .proDescription{padding-right:var(--sm)}
}

@media all and (max-width: 800px){

    #logo{width:35px;bottom:calc(var(--sm) + 3px)}
    #debug{display:none;}
    #footer{
        position:fixed;
        width:80%;
        max-width:80%;
        bottom: var(--sm);
        left: var(--lrg);
    }
    .singlePop.imageLink,.singlePop.videoLink{width:80vw;}


    /* nav */
    .viewport{
        width:100px;height:100px;
    }
    .dot{width:20px;height:20px;}
    .menuBut:hover .dot, .menuBut.active .dot, .dotHold:hover .dot,  .subMenuBut.active .dot, .singlePop .dot, .dotHold:hover .dotMid, .dotMid.active,  .singleMenu:hover .dot, .singleMenu.active .dot{
        border-width:10px;

    }

    .dotMid{
        transform: translateY(-65%);
    }
    .dotHold:hover .dotMid, .dotMid.active{
        transform: scale(.8) translateY(-65%);
    }

    .singleMenu{
    padding: var(--xs) var(--xs) var(--xs) var(--lrg);
     margin-bottom: 5px;
    }
    .subMenuBut{padding-left:var(--lrg);}
    .titleHold{left:var(--lrg)}
    #geo{width:calc(100vh - calc(2 * var(--sm)) - var(--lrg));display:flex;transform:rotate(90deg) translate(100%,-5px);transform-origin: top right;}
    #geo p{margin-right:var(--xs)}

    /* inspire */
    .inspireSingle:hover .contentStroke{
        width:calc(100% - var(--xs));
        height:calc(100% - var(--xs));
    }
    .secDescription{padding-top:var(--lrg)}
    .desHold{padding-left:var(--lrg)}
}

@media all and (max-width:500px){
    .dotMid{
        transform: translateY(-60%);
    }
    .dotHold:hover .dotMid, .dotMid.active{
        transform: scale(.8) translateY(-60%);
    }
}


@media (hover: none) and (pointer: coarse) {
    html.touch *:hover {
        all:inherit!important;
    }
}
