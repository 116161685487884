#root ~ iframe {
  z-index: -9999 !important;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

/* Variables */
:root  {
  --black: #151616;
  --black-rgb: 21, 22, 22;
  /* --white: #e8e2d4; */
  /* --white-rgb: 232, 226, 212; */
  --white: #69c5db;
  --white-rgb: 105, 197, 219;
  --green: #5fa391;
  --blue:#2f456b;
  --rust:#ae4d33;
  --lav:#a091c1;
  --huge: 150px;
  --lrg: 60px;
  --med:40px;
  --sm:20px;
  --xs:15px;
  --gut:30px;
  --mc: calc(100%/6)
  }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
    vertical-align: baseline;
   box-sizing: border-box;
    cursor:none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, div, img {
  display: block;
}

ol, ul {
  list-style: none;
  display: block;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

button, input,input[type="file" i], select{
  -webkit-appearance: none;
  border-radius: 0;
  border: none;
}
input, textarea{
  width: 100%;
  box-sizing: border-box;
}

textarea{
  height: 120px;
  border-color: var(--gray);
  padding: 10px;
}

input, select{
  padding:10px;
  background: transparent;
  border-bottom: 1px solid var(--black);
}
input{
  height: 40px;
}

select{
  position: relative;
  height: auto;
  border: 1px solid #000;
}

textarea{
  background:transparent;
  border: none;
  color: var(--white);
  resize: none;
  height:auto;
  overflow:hidden;
}

textarea:focus {
  outline: none !important;
  border:none;
  box-shadow: none;
}

button{
  background-color: transparent;
  border:none;
}

.selectAfter::after{
  content:"▼";
  z-index:999;
  display:block;
  position: absolute;
  right: var(--xs);
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
/* font embeds */
@font-face {
  font-family: 'serif';
  src: url('/fonts/serif.woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'sans';
  src: url('/fonts/sans.woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'euro';
  src: url('/fonts/sans2.woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'mono';
  src: url('/fonts/mono.woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gambarino';
  src: url('/fonts/Gambarino-Regular.woff2');
  font-weight: 400;
  font-style: normal;
}


/* fonts */
p {

  margin:0;
  padding:0;
}

.tP {
  font-size: 1.7rem;
  font-family: "serif", times, monospace;
  line-height: 1.325em;
  letter-spacing: 1px;
}

.tSmP {
  font-size: 1.375rem;
  font-family: "serif", times, monospace;
  line-height: 1.2em;
  letter-spacing: 1px;
}

.tPBig {
  font-size: 2.25rem;
  font-family: "serif", times, monospace;
  line-height: 1.325em;
}

.tPSub, .quill p{
  font-size: 1.75rem;
  font-family: "euro", times, monospace;
  line-height: 1.5em;
}

sup,.tFooter, .tFooter::placeholder{
  font-size: .9125rem;
  font-family: "mono", courier, monospace;
  line-height: 1.3em;
  letter-spacing: 1px;
}

sup {
  vertical-align: super;
  /* border-radius: 500px; */
  border:1px var(--white) solid;
  padding: 2px 8px 1px;
  z-index:999;
  /* margin-left:-8px;
  margin-right:-8px; */
  position: relative;
  top: 1.5px;
}

h1 sup{
  line-height: 0;
  position:relative;
  top: -1em;
}
sup.active{
  color:var(--black);
  background-color: var(--white)
}

.tSub{
  font-size: 1.5rem;
  font-family: "serif", times, monospace;
  line-height: 1.2em;
  letter-spacing: 1px;
}

h2,.tBig{
  font-family: "serif", times, serif;
  font-size: 9rem;
  line-height:1em;
  margin-block-start: 0;
  margin-block-end:0;
  -webkit-font-smoothing: none;
}

h1{
  font-family: "serif", times, serif;
  font-size: 4.5rem;
  line-height:1em;
  margin-block-start: 0;
  margin-block-end:0;
  -webkit-font-smoothing: none;
}

h3{
  font-family: "serif", times, serif;
  font-size: 10rem;
  line-height:1em;
  margin-block-start: 0;
  margin-block-end:0;
  -webkit-font-smoothing: none;
  filter: blur(.2px);
}


.tFeq{
  font-size: 3.5rem;
  font-family: "mono", courier, monospace;
  line-height: 1.2em;
  letter-spacing: 1px;

}

.tClock{
  font-size: 24rem;
  font-family: "mono", courier, monospace;
  line-height: .75em;
  letter-spacing: 1px;

}


.tLeft{
  text-align: left;
}

.tRight{
  text-align: right;
}

.tCenter{
  text-align:center;
}










/* cta */
a {text-decoration: none;color:inherit}

a:visited{text-decoration:none;}

.quill a{
  text-decoration: underline;
}

.cPointer{
  cursor: pointer;
}

.cNone{
  cursor:none;
}

.eNone{
  pointer-events: none;
}

.eFill{
  pointer-events: auto;
}

.noTouch{
  touch-action: none;
}

.CTA{
  text-align: center;
  border-radius: 500px;
  padding: var(--xs);
  border-width: 3px;
  border-style:solid;
  border-color: var(--black);
  cursor: pointer;
}

.CTAPrime {
  background-color:var(--black);
  color: var(--white);
}

.CTAPrime:hover, .CTASub{
  cursor: pointer;
  color: var(--black);
  background-color: transparent;
}

.CTASec:Hover{
  background-color:var(--black);
  color: var(--white);
}

.CTARandom:hover{
  background-color:var(--black);
  color: var(--white)
}


/* colors */
.bgBlack{
  background-color: var(--black)
}

.bgWhite{
  background-color: var(--white)
}
.bgOffWhite{
  background-color: var(--offWhite)
}

.bgGray{
  background-color: var(--gray)
}

.bgGreen, .bggreen{
  background-color: var(--green)
}
.bgBlue, .bgblue{
  background-color: var(--blue)
}
.bgRust, .bgRust{
  background-color: var(--rust)
}

.tBlack{
  color: var(--black)
}

.tWhite {
  color: rgba(var(--white-rgb), 1);
}
.tWhite::placeholder{
  color: rgba(var(--white-rgb), 0.25);
}

.tGray{
  color: var(--gray)
}

.tGreen{
  color: var(--green)
}
.tRust{
  color: var(--rust)
}
.tBlue{
  color: var(--blue)
}

.tgreen{
  color: var(--green)
}
.tlav{
  color: var(--lav)
}
.tblue{
  color: var(--blue)
}

/* ratios */
.rDouble{
  padding-bottom:50%;
}
.rWide{
  padding-bottom:56.25%;
  }

.r34{
  padding-bottom: 75%;
}

.rSquared{
  padding-bottom: 100%;
}

.rPort{
  padding-bottom:133.33%
}

/* quill */

.quillImage, .quillVideo{
  margin: var(--sm) 0;
  padding:var(--sm);
  border: 1px var(--white) solid;
}

.quillVideo[data-type="quillVideo"] div.rWide {
  padding-bottom: unset;
}
.quillVideo[data-type="quillVideo"] div.rWide div.imageHold.fullWidth.fullHeight {
  height: unset;
  padding: unset;
}
.quillVideo[data-type="quillVideo"] div.rWide div.imageHold.fullWidth.fullHeight iframe {
  width: 100%;
}
.quillVideo[data-type="quillVideo"] iframe {
  aspect-ratio: 16/9;
}

.quill ul{
  padding-left: 10%;
  font-size: 1.5rem;
  font-family: "mono", courier, monospace;
  line-height: 1.4em;
  letter-spacing: 1px;
  margin-top: var(--med);
}

.quill ul li{
  margin-bottom: var(--med);
  position:relative;
}

.quill ul li::before{
  content: "";
  display:block;
  position: absolute;
  top:.2em;
  left:calc(-1*var(--med));
  border-radius: 100%;
  background-color: var(--white);
  width:20px;
  height:20px;
}
/* alignments */
.center{
  margin: 0 auto;
}
.floatLeft{
  float: left;
}

.floatRight{
  float: left;
}

.clearBoth::after{
  content: "";
  clear:both;
  display: block;

}

/* positioning*/
.relative{
  position: relative;
}

.absolute{
  position: absolute;
}




/* container formats*/
.fullWidth{
  width: 100%
}

.fullHeight{
  height: 100%
}

.fullStage{
  width: calc(100% - var(--huge)*2 + var(--gut));
}

.fullFixed{
  position:fixed;
  top:0;
  left:0;

}

.neg{
  opacity:0;
  z-index:0;
}

.flex {
  display: flex;
  justify-content:space-between;
  flex-wrap:wrap;
}


.flexDRev{
  flex-direction: row-reverse;
}

.flexCenter{
  justify-content: center;
}
.flexACenter{
  align-items: center;
}
.flexAStart{
  align-items: start;
}

.flexAEnd{
  align-items: flex-end;
}

.flexStart{
  justify-content: flex-start;
}

.flexEnd{
  justify-content: flex-end;
}


.evenFlex{
  flex-grow: 1;
}

.eCenter{
  margin: 0 auto;
}

.eHide{
  opacity:0;
  display:none;
}

.eLT{
  position:absolute;
  top:0;
  left:0;
}

.eFiller{
  position:absolute;
  top:0;
  left:0;
  z-index:0;
}

.floatL{
  float:left;
}

.cCenter{
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%)
}

.centeredContent{
  position:absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
}

/* columns */
.colThird{
  width:33.33%;
}
.col1{
  width: 10%;
}
.col2{
  width: 20%;
}

.col3{
  width: 30%;
}

.col4{
  width: 40%;
}

.col5{
  width: 50%;
}

.col6{
  width: 60%;
}
.col7{
  width: 70%;
}
.col8{
  width: 80%;
}
.col9{
  width: 90%;
}

.colGut{
  padding: 0 calc(var(--gut)/2);
}





/* background */
.bgCover{
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.bgCoverHeight{
  background-size: auto 100%;
}
.bgWrap
{
  background-repeat: no-repeat;
    background-size: 100% 100%;
}

.shadow{
  box-shadow: 0 0 6px 2px rgba(0,0,0,.3);
  /* border-bottom: 1px solid var(--black) */
}

.shadowTop{
  border-top: 1px solid var(--black)
}

/* margins*/
.mtHuge{
  margin-top: var(--huge);
}
.mtLrg{
  margin-top: var(--lrg);
}

.mtMed{
  margin-top: var(--med);
}

.mtSm{
  margin-top: var(--sm);
}

.mtXs{
  margin-top: var(--xs);
}
.mbHuge{
  margin-bottom: var(--huge);
}

.mbLrg{
  margin-bottom: var(--lrg);
}

.mbMed{
  margin-bottom: var(--med);
}

.mbSm{
  margin-bottom: var(--sm);
}

.mbXs{
  margin-bottom: var(--xs);
}

.mrSm{
  margin-right: var(--sm);
}

/* padding formats */
.pStart{
  padding-top:var(--huge)
}
.ptHuge{
  padding-top: var(--huge);
}
.plHuge{
  padding-left: var(--huge);
}

.ptLrg{
  padding-top: var(--lrg);
}

.ptMed{
  padding-top: var(--med);
}

.ptSm{
  padding-top: var(--sm);
}

.ptXs{
  padding-top: var(--xs);
}

.pbHuge{
  padding-bottom: var(--huge);
}

.pbLrg{
  padding-bottom: var(--lrg);
}

.pbMed{
  padding-bottom: var(--med);
}

.pbSm{
  padding-bottom: var(--sm);
}

.pbXs{
  padding-bottom: var(--xs);
}

.plXs{
  padding-left: var(--xs);
}

.plSm{
  padding-left: var(--sm);
}

.plMed{
  padding-left: var(--med);
}

.prSm{
  padding-right:var(--sm)
}

.prMed{
  padding-right:var(--med)
}


.pXs{
  padding: var(--xs)
}

.pSm{
  padding: var(--sm)
}

.pMed{
  padding: var(--med)
}

.center{
  margin: 0 auto;
}

.serverNav{
  font-weight: bold;
}

/* type Mods*/
.tLower{
  text-transform: lowercase;
}
.tUpper{
  text-transform: uppercase;
}

.tCap{
  text-transform: capitalize;
}


.tBold, strong{
  font-weight: bold;
}

.tItalics {
  font-style: italic;
}

.tCenter{
  text-align: center;
}

.tRight{
  text-align: right;
}

.tCap{
  text-transform: capitalize;
}

.tUnder{
  text-decoration: underline;
}



/* border */
.bBlack{
  border-color: var(--black)
}

.bGray{
  border-color: var(--gray)
}

.bWhite{
  border-color: var(--white)
}

.bBotSm{
  border-bottom-style:solid;
  border-bottom-width: 1px;
}

.bTopSm{
  border-top-style:solid;
  border-top-width: 1px;
}
.bRight{
  border-right: 1px solid;
  border-color: var(--white);
}

.bLeft{
  border-left: 1px;
  border-style:solid;
  border-color: var(--white);
}
.bSm{
  border-width: 1px;
  border-style: solid;
}

.bRound{
  border-radius:100%;
}


/* image mods */
.imgFull{
  width: 100%;
  height: auto;
}
.imgCover{
  width:100%;
  height: 100%;
  object-fit: cover;

}

.imgContain{
  width:100%;
  height: 100%;
  object-position: 50% 50%;
  object-fit: contain;
}

.imgFrame{
  position: absolute;
  top:0;
  left:0;
  padding:40px;

}


.rSquared{
  padding-bottom:100%;
}

.rPortrait{
  padding-bottom:133.33%;
}

.closeMenu{
  width: 50px;
  height:50px;
  position:absolute;
  left:-70px;
  top:10px;
}

.closeMenu:hover{
  background-color:var(--white)
}

.blink {
  animation: blinker 1s ease-in infinite;

}

.blinkFast, .winLink.hoverOn, .hoverOn .jobName  {
  animation: blinker .5s ease-in infinite;

}

.spin{
  animation: button-spin infinite 20s linear;
}

@keyframes button-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(-360deg); }
  }

.noEvent{
  pointer-events: none;
}
@keyframes blinker {
  97% {
    opacity: 0;
  }
  }


@media all and (max-width: 1200px){
    h1{font-size:3.5rem;line-height: 1.1em;}
    h1 sup{top:-8px}

  }

  @media all and (max-width:1100px){

.tFeq{font-size: 2.5rem;}
.tClock{font-size:15rem;}
}

  @media all and (max-width: 1000px){
  .tPBig{font-size:1.75rem;}
  .tBig{font-size:5rem;}
}
@media all and (max-width: 900px){
  .tPSub, .quill p{
    font-size: 1.25rem;
  }
}

  @media all and (max-width: 800px){

  h1{font-size:2.5rem;line-height: 1.15em;}
  h1 sup{top:-4px}
  .pStart{padding-top:var(--med)}
  .fullStage{
    width: calc(100% - var(--lrg)*2 + var(--gut));
  }

}

@media all and (max-width: 600px){
  .imgFrame{padding:var(--sm)}
  sup{font-size:.6rem;}
  .tBig{font-size:3.75rem;line-height:1.125em;}
  .quill ul{font-size:1rem;}
  .quillImage, .quillVideo {margin: var(--xs) 0}
  h3{font-size:5em}
}

@media all and (max-width:500px){
  h1{font-size:1.625rem; line-height:1.2em;}
  .tPBig{font-family: "euro", times, monospace;font-size:.875rem;line-height:1.6em}
  h1 sup{top:2px;}
  .tPSub, .quill p{font-size: 1rem;}
  :root  {

    --huge: 100px;
    --lrg: 50px;
    --med:30px;
    --sm:15px;
    --xs:10px;
    --gut:30px;
    --mc: calc(100%/6)
    }
}

@media (hover: none) and (pointer: coarse) {
  .eFill{cursor: pointer !important;}
  .sizer{pointer-events: none;}
}
