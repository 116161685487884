/* windicss layer base */

/* windicss layer components */
.bg-image-contain {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.bg-image-cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.inset-center {
  top: 50%;
  left: 50%;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-translate-z: 0;
  --tw-rotate: 0;
  --tw-rotate-x: 0;
  --tw-rotate-y: 0;
  --tw-rotate-z: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
}
.inset-x-center {
  left: 50%;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-translate-z: 0;
  --tw-rotate: 0;
  --tw-rotate-x: 0;
  --tw-rotate-y: 0;
  --tw-rotate-z: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  --tw-translate-x: -50%;
}
.inset-y-center {
  top: 50%;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-translate-z: 0;
  --tw-rotate: 0;
  --tw-rotate-x: 0;
  --tw-rotate-y: 0;
  --tw-rotate-z: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  --tw-translate-y: -50%;
}
/* windicss layer utilities */
.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.bg-current {
  background-color: currentColor;
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 22, 22, var(--tw-bg-opacity));
}
.hover\:bg-cyan:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(105, 197, 219, var(--tw-bg-opacity));
}
.after\:bg-current::after {
  background-color: currentColor;
}
.bg-cyan {
  --tw-bg-opacity: 1;
  background-color: rgba(105, 197, 219, var(--tw-bg-opacity));
}
.hover\:bg-opacity-10:hover {
  --tw-bg-opacity: 0.1;
}
.bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}
.bg-left-top {
  background-position: left top;
}
.bg-center {
  background-position: center;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-contain {
  background-size: contain;
}
.bg-cover {
  background-size: cover;
}
.border-current {
  border-color: currentColor;
}
.border-cyan {
  --tw-border-opacity: 1;
  border-color: rgba(105, 197, 219, var(--tw-border-opacity));
}
.border-opacity-50 {
  --tw-border-opacity: 0.5;
}
.hover\:border-opacity-100:hover {
  --tw-border-opacity: 1;
}
.border-solid {
  border-style: solid;
}
.hover\:border-solid:hover {
  border-style: solid;
}
.border-dashed {
  border-style: dashed;
}
.border {
  border-width: 1px;
}
.hover\:border-5:hover {
  border-width: 5px;
}
.hover\:border-2:hover {
  border-width: 2px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-l {
  border-left-width: 1px;
}
.cursor-pointer {
  cursor: pointer;
}
.all\:block * {
  display: block;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.all\:inline * {
  display: inline;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.grid {
  display: -ms-grid;
  display: grid;
}
.\!hidden {
  display: none !important;
}
.hidden {
  display: none;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.\!flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  -webkit-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.self-start {
  -ms-flex-item-align: start;
  -webkit-align-self: flex-start;
  align-self: flex-start;
}
.self-end {
  -ms-flex-item-align: end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
}
.\!justify-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  -webkit-justify-content: flex-start !important;
  justify-content: flex-start !important;
}
.\!justify-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-mono {
  font-family: mono,courier,monospace;
}
.font-serif {
  font-family: Gambarino,Times New Roman,serif;
}
.font-sans {
  font-family: euro,times,monospace;
}
.h-1\.5 {
  height: 0.375rem;
}
.h-full {
  height: 100%;
}
.h-\[1\.5em\] {
  height: 1.5em;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.leading-tight {
  line-height: 1.25;
}
.-m-1 {
  margin: -0.25rem;
}
.m-4 {
  margin: 1rem;
}
.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.-mt-2 {
  margin-top: -0.5rem;
}
.ml-16 {
  margin-left: 4rem;
}
.mr-16 {
  margin-right: 4rem;
}
.ml-8 {
  margin-left: 2rem;
}
.mr-8 {
  margin-right: 2rem;
}
.mb-\[calc\(-0\.5em-0\.125rem\)\] {
  margin-bottom: calc(-0.5em - 0.125rem);
}
.mt-1 {
  margin-top: 0.25rem;
}
.max-h-40 {
  max-height: 10rem;
}
.max-w-full {
  max-width: 100%;
}
.max-w-3xl {
  max-width: 48rem;
}
.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-25 {
  opacity: 0.25;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.p-5 {
  padding: 1.25rem;
}
.p-4 {
  padding: 1rem;
}
.hover\:p-3:hover {
  padding: 0.75rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hover\:px-1\.75:hover {
  padding-left: 0.4375rem;
  padding-right: 0.4375rem;
}
.pt-1\.25 {
  padding-top: 0.3125rem;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.hover\:pt-1:hover {
  padding-top: 0.25rem;
}
.hover\:pb-0\.75:hover {
  padding-bottom: 0.1875rem;
}
.pointer-events-auto {
  pointer-events: auto;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.after\:absolute::after {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.after\:inset-0::after {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.inset-x-0 {
  right: 0px;
  left: 0px;
}
.-right-15 {
  right: -3.75rem;
}
.-bottom-3\.5 {
  bottom: -0.875rem;
}
.top-0 {
  top: 0px;
}
.top-4 {
  top: 1rem;
}
.right-10 {
  right: 2.5rem;
}
.bottom-16 {
  bottom: 4rem;
}
.left-4 {
  left: 1rem;
}
.top-2 {
  top: 0.5rem;
}
.right-2 {
  right: 0.5rem;
}
.-bottom-1 {
  bottom: -0.25rem;
}
.-left-1 {
  left: -0.25rem;
}
.right-4 {
  right: 1rem;
}
.-bottom-2 {
  bottom: -0.5rem;
}
.-left-2 {
  left: -0.5rem;
}
.bottom-0 {
  bottom: 0px;
}
.top-1\/2 {
  top: 50%;
}
.left-1\/2 {
  left: 50%;
}
.resize {
  resize: both;
}
.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-cyan {
  --tw-text-opacity: 1;
  color: rgba(105, 197, 219, var(--tw-text-opacity));
}
.truncate {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.all\:truncate * {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.content-type {
  content: "type";
}
.tabular-nums {
  --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
  --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-spacing: tabular-nums;
  --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}
.tracking-wider {
  letter-spacing: 0.05em;
}
.visible {
  visibility: visible;
}
.w-7 {
  width: 1.75rem;
}
.w-16 {
  width: 4rem;
}
.w-64 {
  width: 16rem;
}
.w-full {
  width: 100%;
}
.w-12 {
  width: 3rem;
}
.z-1 {
  z-index: 1;
}
.-z-1 {
  z-index: -1;
}
.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-translate-z: 0;
  --tw-rotate: 0;
  --tw-rotate-x: 0;
  --tw-rotate-y: 0;
  --tw-rotate-z: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
}
.origin-top-right {
  -webkit-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right;
}
.origin-top-left {
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
}
.translate-y-0 {
  --tw-translate-y: 0px;
}
.-translate-y-0\.25 {
  --tw-translate-y: -0.0625rem;
}
.-translate-x-1\/2 {
  --tw-translate-x: -50%;
}
.-translate-y-1\/2 {
  --tw-translate-y: -50%;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.transition-transform {
  -webkit-transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.transition {
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-out {
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.\!duration-2000 {
  -webkit-transition-duration: 2000ms !important;
  -o-transition-duration: 2000ms !important;
  transition-duration: 2000ms !important;
}
.duration-300 {
  -webkit-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  transition-duration: 300ms;
}
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
@-webkit-keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
@-webkit-keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
.animate-pulse {
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.hover\:animate-pulse:hover {
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.backdrop-filter {
  --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
}
.scrollbar-hide {
  scrollbar-width: none;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.backdrop-blur {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
.blurStage {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
@media (min-width: 480px) {
  .xs\:right-12 {
    right: 3rem;
  }
}
@media (min-width: 640px) {
  .sm\:border-current {
    border-color: currentColor;
  }
  .sm\:border-solid {
    border-style: solid;
  }
  .sm\:border-l {
    border-left-width: 1px;
  }
  .sm\:grid {
    display: -ms-grid;
    display: grid;
  }
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 768px) {
  .md\:top-8 {
    top: 2rem;
  }
  .md\:right-40 {
    right: 10rem;
  }
  .md\:bottom-24 {
    bottom: 6rem;
  }
  .md\:left-16 {
    left: 4rem;
  }
}