
.playBut{
    padding: var(--sm);
    width:140px;
}

@media all and (max-width: 800px){
    .thoughtSingleHold{
        padding-top:90px;
    }
}

@media all and (max-width: 600px){
   .playBut{width:120px;}
}