.inspireSingle{
    margin: var(--med) auto 0 auto;
}

.contentImgHold{
   position: absolute;
   top:0;
   left:0;
}

.imgHold{
    position: absolute;
    top:0;
    left:0;
    padding:var(--med);
}

.rmovie{
    width:60%;
}

.movieBuff{
    padding-bottom: 134%;
}

.rmusic{
    width: 30%
}

.musicBuff{
    padding-bottom:100%;
}

.rartist{
    width:35%;
}
.artistBuff{
    padding-bottom:150%;
}

.rartist div, .rartist img{
    border-radius: 100%;
}

.rvisual{
    width: 60%;
}
.visualBuff{
    padding-bottom: 56.25%;
}

.inspiroBack{
    position:fixed;
    top:0;
    left:0;
    z-index:999;
}


.inspireStage{
    z-index: 200;
    position:fixed;
    top:0;
    left:0;
    overflow: hidden;
}


/* mobile shit */
@media all and (max-width: 800px){
    .imgHold{padding:var(--sm)}
    .inspiroBack{padding-left:var(--lrg);}



}

@media all and (max-width: 600px){
    .imgHold{padding:var(--sm);}
    .rmusic,.rvisual,.rmovie,.rartist{width:100%;}

}

.columns {
  columns: 1;
}
.columns:not(:first-child) {
  margin-top: 32px;
}
.columns > * {
  break-inside: avoid;
}
.columns > *:not(:first-child) {
  margin-top: 24px;
}
@media (min-width: 768px) {
  .columns {
    columns: 2;
    gap: 32px;
  }
  .columns:not(:first-child) {
    margin-top: 40px;
  }
  .columns > *:not(:first-child) {
    margin-top: 32px;
  }
}
@media (min-width: 1680px) {
  .columns {
    columns: 3;
    gap: 48px;
  }
  .columns:not(:first-child) {
    margin-top: 48px;
  }
  .columns > *:not(:first-child) {
    margin-top: 48px;
  }
}
